import { createSlice } from '@reduxjs/toolkit'

export const artistContactInfoSlice = createSlice({
  name: 'artistContactInfo',
  initialState: {
    address: '',
    socialMedia: { instagram: '', email: '' },
  },
  reducers: {
    setArtistContactInfo: (state, action) => {
      state.address = action.payload.address
      state.socialMedia = action.payload.socialMedia
    },
  },
})

export const { setArtistContactInfo } = artistContactInfoSlice.actions

export default artistContactInfoSlice.reducer
