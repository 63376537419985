import { useEffect, useState } from 'react'
import './styles.css'
import MediaQuery from 'react-responsive'
import { useSelector } from 'react-redux'
import { GlobalStateType } from '../../types/globalStateType'
import * as DOMPurify from 'dompurify'

export default function Vita() {
  const { biographyDE, biographyEN, image } = useSelector(
    (state: GlobalStateType) => state.artistBiographySlice,
  )

  const handleProcedureContentChange = (content: string) => {
    if (content === '<p><br></p>') content = ''
    const sanitizedHtml = DOMPurify.sanitize(content)
    // const styledHtml = `<div style="font-size: 16px;">${sanitizedHtml}</div>`
    return sanitizedHtml
  }

  const [language, setLanguage] = useState('de')
  const [text, setText] = useState('')
  const [rerenderKey, setRerenderKey] = useState(0)

  const toggleLanguage = () => {
    setLanguage(language === 'de' ? 'en' : 'de')
  }

  useEffect(() => {
    setText(
      language === 'de'
        ? handleProcedureContentChange(biographyDE)
        : handleProcedureContentChange(biographyEN),
    )
  }, [language, biographyDE, biographyEN])

  useEffect(() => {
    setRerenderKey(rerenderKey + 1)
  }, [])

  return (
    <div>
      <MediaQuery minWidth={1224}>
        <div id='vita' className='vita-external-container'>
          <h1> VITA </h1>
          <div className='vita-internal-container'>
            <div className='vita-text-container'>
              <div className='language-toggle-container'>
                <button onClick={toggleLanguage} className='language-toggle-button'>
                  {language === 'de' ? 'English' : 'Deutsch'}
                </button>
              </div>
              <div className='vita-text' dangerouslySetInnerHTML={{ __html: text }} />
            </div>
            <div className={'vita-img-container'}>
              <img src={image} alt={'vita-img'} />
            </div>
          </div>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={1224}>
        <div id='vita' className='vita-external-container'>
          <h1> VITA </h1>
          <div className='vita-internal-container-mobile'>
            <div className={'vita-img-container-mobile'}>
              <img src={image} alt={'vita-img'} />
            </div>
            <div className='vita-text-container-mobile'>
              <div className='language-toggle-container'>
                <button onClick={toggleLanguage} className='language-toggle-button'>
                  {language === 'de' ? 'English' : 'Deutsch'}
                </button>
              </div>
              <div
                className='vita-text-mobile'
                dangerouslySetInnerHTML={{ __html: text }}
                key={rerenderKey}
              />
            </div>
          </div>
        </div>
      </MediaQuery>
    </div>
  )
}
