import { createSlice } from '@reduxjs/toolkit'

export const artistInformationSlice = createSlice({
  name: 'artistInformation',
  initialState: {
    fullName: '',
    name: '',
    familyName: '',
    profession: '',
  },
  reducers: {
    setArtistPersonalInfromation: (state, action) => {
      state.fullName = action.payload.fullName
      state.name = action.payload.name
      state.familyName = action.payload.familyName
      state.profession = action.payload.profession
    },
  },
})

// Action creators are generated for each case reducer function
export const { setArtistPersonalInfromation } = artistInformationSlice.actions

export default artistInformationSlice.reducer
