import './styles.css'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { SocialMediaInfo } from './SocialMediaInfo'

export default function Contact() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  return (
    <div
      id='contact'
      className={`personal-information-external-wrapper personal-information-external-wrapper${
        isTabletOrMobile ? '-mobile' : ''
      }`}
    >
      <h1>KONTAKT</h1>
      <div className={`personal-information-internal-wrapper${isTabletOrMobile ? '-mobile' : ''}`}>
        <div
          className={`contact-information contact-information${isTabletOrMobile ? '-mobile' : ''}`}
        >
          <SocialMediaInfo />
        </div>
        <div
          className={`extra-contact-information extra-contact-information${
            isTabletOrMobile ? '-mobile' : ''
          }`}
        >
          <Link to={'/imprint'}>Impressum</Link>
          <br />
          <Link to={'/dataPrivacy'}>Datenschutz</Link>
        </div>
      </div>
      <div className='copyright'>
        <a className='github-link' href='https://github.com/talbizzz'>
          © Github | @talbizzz
        </a>
      </div>
    </div>
  )
}
