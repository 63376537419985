import { configureStore } from '@reduxjs/toolkit'
import carouselTranslationSlice from './feature/carousselTranslation/carouselTranslationSlice'
import openBackdropImageSlice from './feature/openBackdropImage/openBackdropImageSlice'
import currentImageSlice from './feature/currentImage/currentImageSlice'
import artistInformationSlice from './feature/artistInformation/artistInformationSlice'
import artistContactInfoSlice from './feature/artistContactInfo/artistContactInfoSlice'
import youtubeVideosSlice from './feature/artistAssets/youtubeVideoSlice'
import imagesSlice from './feature/artistAssets/imagesSlice'
import audiosSlice from './feature/artistAssets/audiosSlice'
import scheduleSlice from './feature/artistSchedule/scheduleSlice'
import artistBiographySlice from './feature/artistBiography/artistBiographySlice'
export default configureStore({
  reducer: {
    carouselTranslation: carouselTranslationSlice,
    openBackdropImage: openBackdropImageSlice,
    currentImage: currentImageSlice,
    artistInformation: artistInformationSlice,
    artistContactInfo: artistContactInfoSlice,
    youtubeVideosSlice: youtubeVideosSlice,
    imagesSlice: imagesSlice,
    audiosSlice: audiosSlice,
    scheduleSlice: scheduleSlice,
    artistBiographySlice: artistBiographySlice,
  },
})
