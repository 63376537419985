import { createSlice } from '@reduxjs/toolkit'

export const artistBiographySlice = createSlice({
  name: 'artistBiography',
  initialState: {
    biographyDE: '',
    biographyEN: '',
    image: '',
  },
  reducers: {
    setArtistBiography: (state, action) => {
      state.biographyDE = action.payload.textDE
      state.biographyEN = action.payload.textEN
      state.image = action.payload.image
    },
  },
})

export const { setArtistBiography } = artistBiographySlice.actions

export default artistBiographySlice.reducer
