import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import './styles.css'
import { AiOutlineMenu } from 'react-icons/ai'
import MediaQuery from 'react-responsive'

export default function NavBar() {
  const [openMobileNavbar, setOpenMobileNavbar] = React.useState(false)
  const location = useLocation()

  React.useEffect(() => {
    if (location.hash) {
      const elem: HTMLElement = document.getElementById(location.hash.slice(1)) as HTMLElement
      if (elem) {
        elem.scrollIntoView({ behavior: 'smooth' })
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
  }, [location])

  const toggleMobileNavbar = (open: boolean) => setOpenMobileNavbar(open)

  return (
    <div>
      <MediaQuery minWidth={1224}>
        <div className='links-container'>
          <Link to='/#top'>Home</Link>
          <Link to='/#vita'>Vita</Link>
          <Link to='/#schedule'>Termine</Link>
          <Link to='/#gallery'>Galerie</Link>
          <Link to='/#media'>Media</Link>
          <Link to='/#contact'>Kontakt</Link>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={1224}>
        {!openMobileNavbar ? (
          <div className={'navbar-mobile-button'} onClick={() => toggleMobileNavbar(true)}>
            <AiOutlineMenu color={'rgb(70 89 100)'} size={30} />
          </div>
        ) : (
          <div className={'navbar-mobile'} onClick={() => toggleMobileNavbar(false)}>
            <Link to='/#top'>
              <p>Home</p>
            </Link>
            <Link to='/#vita'>
              <p>Vita</p>
            </Link>
            <Link to='/#schedule'>
              <p>Termine</p>
            </Link>
            <Link to='/#gallery'>
              <p>Galerie</p>
            </Link>
            <Link to='/#media'>
              <p>Media</p>
            </Link>
            <Link to='/#contact'>
              <p>Kontakt</p>
            </Link>
          </div>
        )}
      </MediaQuery>
    </div>
  )
}
