import React from 'react'
import './styles.css'
import { useDispatch } from 'react-redux'
import { changeCurrent } from '../../../store/feature/currentImage/currentImageSlice'
import { open } from '../../../store/feature/openBackdropImage/openBackdropImageSlice'

export default function OpenableImage({
  image,
  imageCopyright,
}: {
  image: string
  imageCopyright: string
}) {
  const dispatch = useDispatch()
  const handleToggle = () => {
    dispatch(open())
    dispatch(changeCurrent({ image, imageCopyright }))
  }

  return (
    <div className='openable-image-container'>
      <div className='image-container' onClick={handleToggle}>
        <img src={image} alt='portrait-image' className='image' />
      </div>
    </div>
  )
}
