import Carousel, { CarouselItem } from '../carousel'
import OpenableImage from '../openableImage'
import './styles.css'
import { useSelector } from 'react-redux'
import { GlobalStateType } from '../../../types/globalStateType'
import React from 'react'

export default function homescreenGalleryPreview() {
  const images = useSelector((state: GlobalStateType) => state.imagesSlice.images)

  return (
    <div id='gallery' className='gallery'>
      <h1>GALERIE</h1>
      <Carousel>
        {images?.map((image, index) => (
          <CarouselItem key={index}>
            <OpenableImage image={image.url} imageCopyright={image.copyright} />
          </CarouselItem>
        ))}
      </Carousel>
    </div>
  )
}
