import { rebekaDocumentId } from '..'
import { db } from '../config/firebase'
import { DocumentData, DocumentReference, doc, getDoc, setDoc } from 'firebase/firestore/lite'
import { ArtistContactInfoType } from '../types/artistContactInfoType'
import { AudioType, ImageType, YoutubeVideoType } from '../types/artistAssetsTypes'

export const getArtistInfo = async () => {
  const personalDataDocRef = doc(db, 'artist-personalData', rebekaDocumentId)
  try {
    const data = await getDoc(personalDataDocRef)
    return data.data()
  } catch (error) {
    console.error('error: ', error)
  }
}

export const getArtistContactInfo = async () => {
  const contactInfoDocRef = doc(db, 'artist-contactInfo', rebekaDocumentId)
  try {
    const data = await getDoc(contactInfoDocRef)
    const result: ArtistContactInfoType = data.data() as ArtistContactInfoType
    return result
  } catch (error) {
    console.error('error: ', error)
  }
}

export const getArtistImages = async () => {
  const assetsDocRef = doc(db, 'artist-assets', rebekaDocumentId + '-images')
  try {
    const data = await getDoc(assetsDocRef)
    return data.data() as ImageType[]
  } catch (error) {
    console.error('error: ', error)
  }
}

export const getArtistAudios = async () => {
  const assetsDocRef = doc(db, 'artist-assets', rebekaDocumentId + '-audios')
  try {
    const data = await getDoc(assetsDocRef)
    return data.data() as AudioType[]
  } catch (error) {
    console.error('error: ', error)
  }
}

export const getArtistYoutubeVideos = async () => {
  const assetsDocRef = doc(db, 'artist-assets', rebekaDocumentId + '-youtubeVideos')
  try {
    const data = await getDoc(assetsDocRef)
    return data.data() as YoutubeVideoType[]
  } catch (error) {
    console.error('error: ', error)
  }
}

export const getArtistSchedule = async () => {
  const scheduleDocRef = doc(db, 'artist-appointments', rebekaDocumentId)
  try {
    const data = await getDoc(scheduleDocRef)
    return data.data()
  } catch (error) {
    console.error('error: ', error)
  }
}

export const getArtistBiography = async () => {
  const biographyDocRef = doc(db, 'artist-biography', rebekaDocumentId)
  try {
    const data = await getDoc(biographyDocRef)
    return data.data()
  } catch (error) {
    console.error('error: ', error)
  }
}

export const postDataToFirestore = async (
  ref: DocumentReference<DocumentData, DocumentData>,
  data: object,
) => {
  try {
    await setDoc(ref, { data })
    console.log('Document successfully written!')
  } catch (error) {
    console.error('error: ', error)
  }
}
