import { createSlice } from '@reduxjs/toolkit'

export const scheduleSlice = createSlice({
  name: 'artistSchedule',
  initialState: {
    schedule: [],
  },
  reducers: {
    setSchedule: (state, action) => {
      state.schedule = action.payload.data
    },
  },
})

export const { setSchedule } = scheduleSlice.actions

export default scheduleSlice.reducer
