import React from 'react'
import { useSelector } from 'react-redux'
import { GlobalStateType } from '../../types/globalStateType'

export const SocialMediaInfo = () => {
  const { fullName, profession } = useSelector((state: GlobalStateType) => state.artistInformation)
  const { socialMedia } = useSelector((state: GlobalStateType) => state.artistContactInfo)

  const socialMediaInfo = [
    {
      id: 'insta',
      path: socialMedia?.instagram,
      imgSrc:
        'https://assets.website-files.com/6103e0e693e9fa98892cce9e/61065d407c793ad706d35c9e_insta-white.svg',
      alt: 'instagram icon',
    },
    {
      id: 'mail',
      path: `mailto:${socialMedia?.email}`,
      imgSrc:
        'https://assets.website-files.com/6103e0e693e9fa98892cce9e/61065d407c793a4c2fd35c9b_mail-white.svg',
      alt: 'mail icon',
    },
  ]

  return (
    <>
      {socialMediaInfo.map((item) => {
        return (
          <div key={item.id}>
            <a href={item.path}>
              <img src={item.imgSrc} alt={item.alt} />
              <p>
                {fullName} - {profession}
              </p>
            </a>
          </div>
        )
      })}
    </>
  )
}
