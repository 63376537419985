import React from 'react'
import './styles.css'
import imprintText from './imprintText'
import { useSelector } from 'react-redux'
import { GlobalStateType } from '../../types/globalStateType'
export default function Impressum() {
  const { fullName, profession } = useSelector((state: GlobalStateType) => state.artistInformation)
  const { address, socialMedia } = useSelector((state: GlobalStateType) => state.artistContactInfo)
  return (
    <div className='external-wrapper'>
      <h1>Impressum</h1>
      <div>
        <h2>
          Angaben gemäß § 5 TMG
          <br />
        </h2>
        {fullName}, {profession}.
        <br />
        {address}
        <h2>Kontakt</h2>
        E-mail: <a href={`mailto:${socialMedia?.email}`}>rebeka_stojkoska@yahoo.com</a>
      </div>
      <div dangerouslySetInnerHTML={{ __html: imprintText }} />
    </div>
  )
}
