import { createSlice } from '@reduxjs/toolkit'

export const carouselTranslationSlice = createSlice({
  name: 'xTranslation',
  initialState: {
    value: 0,
  },
  reducers: {
    increment: (state, nbImages) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      const max = nbImages.payload / 2 / 2 + 1
      state.value = Math.min(state.value + 1, max)
    },
    decrement: (state, nbImages) => {
      const min = -nbImages.payload / 2 / 2 - 1
      state.value = Math.max(state.value - 1, min)
    },
  },
})

// Action creators are generated for each case reducer function
export const { increment, decrement } = carouselTranslationSlice.actions

export default carouselTranslationSlice.reducer
