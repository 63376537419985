import './styles.css'
import { useDispatch, useSelector } from 'react-redux'
import { close } from '../../store/feature/openBackdropImage/openBackdropImageSlice'
import MediaQuery from 'react-responsive'
import { useNavigate } from 'react-router-dom'

interface state {
  openBackdropImage: { value: boolean }
  currentImage: { imageUrl: string; imageCopyright: string }
}

export default function Gallery() {
  const openBackdrop = useSelector((state: state) => state.openBackdropImage.value)
  const currentImage = useSelector((state: state) => state.currentImage?.imageUrl)
  const currentCopyright = useSelector((state: state) => state.currentImage?.imageCopyright)

  const dispatch = useDispatch()
  const handleClose = () => {
    dispatch(close())
    navigate('/#gallery')
  }
  const navigate = useNavigate()
  return (
    <div id='gallery' className='gallery-container'>
      {openBackdrop && (
        <div className='opened-image-container' onClick={handleClose}>
          <div className='opened-image-container'>
            <MediaQuery minWidth={1224}>
              <div className='image-internal-container'>
                <img src={currentImage} alt='img' />
                <h3>© {currentCopyright}</h3>
              </div>
              <div onClick={handleClose} className='close-image'>
                x
              </div>
            </MediaQuery>
          </div>
          <MediaQuery maxWidth={1224}>
            <div className='opened-image-container-mobile'>
              <div
                className='image-internal-container-mobile'
                style={{
                  background: `url(${currentImage}) center / contain no-repeat`,
                }}
              />
              <h3>© {currentCopyright}</h3>
            </div>
          </MediaQuery>
        </div>
      )}
    </div>
  )
}
