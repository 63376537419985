import React from 'react'
import './styles.css'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import MediaQuery from 'react-responsive'
import { useSelector } from 'react-redux'
import { GlobalStateType } from '../../types/globalStateType'
import { AppointmentType } from '../../types/appointmentType'

export const dateOfString = (stringDate: string): Date => {
  const [day, month, year] = stringDate.split('.')

  // Subtract 1 from the month since JavaScript Date months are zero-based (0-11)
  const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day))
  return date
}

const filterAppointments = (newer: boolean, appointments: AppointmentType[]): AppointmentType[] => {
  const today = new Date() // Get today's date
  const newerAppointments: AppointmentType[] = []
  const olderAppointments: AppointmentType[] = []
  appointments.forEach((appointment) => {
    const appointmentDate = dateOfString(appointment.date)
    if (appointmentDate < today) {
      // The appointment date is in the past
      olderAppointments.push(appointment)
    } else {
      // The appointment date is in the future or today
      newerAppointments.push(appointment)
    }
  })

  return newer ? newerAppointments : olderAppointments
}

// Define a function to compare appointment dates for sorting
const compareAppointmentsByDate = (a: AppointmentType, b: AppointmentType) => {
  const dateA = dateOfString(a.date)
  const dateB = dateOfString(b.date)

  if (dateA < dateB) {
    return -1
  } else if (dateA > dateB) {
    return 1
  } else {
    return 0
  }
}

export default function Schedule() {
  const appointments = useSelector((state: GlobalStateType) => state.scheduleSlice.schedule)

  const [newerAppointments, setNewerAppointments] = React.useState<AppointmentType[]>([])
  const [olderAppointments, setOlderAppointments] = React.useState<AppointmentType[]>([])

  React.useEffect(() => {
    if (appointments) {
      setNewerAppointments(filterAppointments(true, appointments).sort(compareAppointmentsByDate))
      setOlderAppointments(filterAppointments(false, appointments).sort(compareAppointmentsByDate))
    }
  }, [appointments])
  const [showUpcoming, setShowUpcoming] = React.useState(true)
  const [shownAppointments, setShownAppointments] =
    React.useState<AppointmentType[]>(newerAppointments)

  React.useEffect(() => {
    setShownAppointments(showUpcoming ? newerAppointments : olderAppointments)
  }, [showUpcoming, newerAppointments, olderAppointments])

  return (
    <div id='schedule'>
      <div className='appointments-external-wrapper'>
        <MediaQuery minWidth={1224}>
          <h1> Termine </h1>
          <div className='appointments-internal-wrapper'>
            {shownAppointments ? (
              shownAppointments?.map((appointment: AppointmentType) => (
                <Accordion key={appointment.id} className='accordion'>
                  <AccordionSummary aria-controls='panel1a-content' id='panel1a-header'>
                    <div className='single-appointment-wrapper' key={appointment.id}>
                      <div className='icon-title-address'>
                        <div className='date'>
                          <h2>
                            {dateOfString(appointment.date).getDate().toString().padStart(2, '0')}.
                            <span>
                              {(dateOfString(appointment.date).getMonth() + 1)
                                .toString()
                                .padStart(2, '0')}
                            </span>
                          </h2>
                        </div>
                        <div className='title-address'>
                          <h3>
                            {appointment.title}
                            <span className='location'>/ {appointment.location}</span>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      <p>{`${appointment.date}, ${appointment.time ?? ''}`}</p>
                    </div>
                    <p>{appointment.address}</p>
                    {appointment.performers?.map((performer, index) => (
                      <p key={index}>
                        <b>
                          {performer?.name}, {performer?.role}
                        </b>
                      </p>
                    ))}
                    {appointment.eventLink && (
                      <button className='more-info'>
                        <a href={appointment.eventLink}>mehr info</a>
                      </button>
                    )}
                  </AccordionDetails>
                </Accordion>
              ))
            ) : (
              <div>Derzeit sind keine Termine verfügbar...</div>
            )}
          </div>
          <div
            onClick={() => setShowUpcoming((currentValue) => !currentValue)}
            className='shownAppointments-toggle-button'
          >
            {showUpcoming ? 'Vergangene Termine anzeigen' : 'Neue Termine Anzeigen'}
          </div>
        </MediaQuery>
      </div>
      <div className='appointments-external-wrapper appointments-external-wrapper-mobile'>
        <MediaQuery maxWidth={1224}>
          <h1> Termine </h1>
          <div>
            {shownAppointments ? (
              shownAppointments.map((appointment: AppointmentType, index) => (
                <div className='accordion-moreButton-wrapper' key={index}>
                  <Accordion>
                    <AccordionSummary>
                      <div className='event-bg-mobile-wrapper'>
                        <div className='date-title-mobile'>
                          <h2>
                            {dateOfString(appointment.date).getDate().toString().padStart(2, '0')}.
                            <span>
                              {(dateOfString(appointment.date).getMonth() + 1)
                                .toString()
                                .padStart(2, '0')}
                            </span>
                            {' - '}
                            {appointment.title}
                          </h2>
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className='accordion-details'>
                        <div className={'indented'}>
                          {appointment.performers?.map((performer, index) => (
                            <span key={index}>
                              {performer?.name}, {performer?.role}
                              <br />
                            </span>
                          ))}
                          <br />
                        </div>
                        <br />
                        {appointment.date && (
                          <div>
                            <span className={'indented'}>{`${appointment.date}, ${
                              appointment.time ?? ''
                            }`}</span>
                          </div>
                        )}
                        <br />
                        {appointment.location && (
                          <div>
                            <span className={'indented'}>{appointment.location}</span>
                          </div>
                        )}
                        {appointment.address && (
                          <div>
                            <span className={'indented'}>{appointment.address}</span>
                          </div>
                        )}
                      </div>
                    </AccordionDetails>
                    {appointment.eventLink && (
                      <div className='more-info-mobile'>
                        <a href={appointment.eventLink}>mehr info</a>
                      </div>
                    )}
                  </Accordion>
                </div>
              ))
            ) : (
              <div>Derzeit sind keine Termine verfügbar...</div>
            )}
          </div>
          <div
            onClick={() => setShowUpcoming((currentValue) => !currentValue)}
            className='shownAppointments-toggle-button'
          >
            {showUpcoming ? 'Vergangene Termine anzeigen' : 'Neue Termine Anzeigen'}
          </div>
        </MediaQuery>
      </div>
    </div>
  )
}
