import React from 'react'
import './styles.css'
import Vita from '../../components/vita'
import Schedule from '../../components/schedule'
import Contact from '../../components/contact'
import HomescreenGalleryPreview from '../../components/gallery/homescreenGalleryPreview'
import Media from '../../components/media'
import { useMediaQuery } from 'react-responsive'
import { useSelector } from 'react-redux'
import { GlobalStateType } from '../../types/globalStateType'

export default function Home() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const bgSizeScaledOnuserWidth = window.innerWidth > window.innerHeight
  const [containerId, setContainerId] = React.useState(0)

  React.useEffect(() => {
    console.log(window.innerWidth)
    setContainerId((prevState) => prevState + 1)
  }, [window.innerWidth])

  const { fullName, profession } = useSelector((state: GlobalStateType) => state.artistInformation)

  const getBackgroundSize = (): string => {
    if (bgSizeScaledOnuserWidth) return '100vw'
    else return '80vh'
  }

  return (
    <div
      key={containerId}
      className={!bgSizeScaledOnuserWidth ? 'background-mobile' : 'background-web'}
      style={{ backgroundSize: getBackgroundSize() }}
    >
      <div id='top' className={`name-profession-container${isTabletOrMobile ? '-mobile' : ''}`}>
        <div
          id='top'
          className={`name-profession-innercontainer${isTabletOrMobile ? '-mobile' : ''}`}
        >
          <div style={styles.fullnameContainer}>{fullName.toUpperCase()}</div>
          <div
            style={isTabletOrMobile ? styles.professionContainerMobile : styles.professionContainer}
          >
            {profession.toLowerCase()}
          </div>
        </div>
      </div>
      <Vita />
      <Schedule />
      <HomescreenGalleryPreview />
      <Media />
      <Contact />
    </div>
  )
}

const styles = {
  fullnameContainer: {
    fontSize: 'xx-large',
    fontWeight: 'bold',
    color: '#fff',
  },
  professionContainer: {
    fontSize: 'xx-large',
    fontWeight: 'bold',
    color: '#fff',
  },
  professionContainerMobile: {
    fontSize: 'x-large',
    fontWeight: 'bold',
    color: '#fff',
  },
}
