import React, { ReactElement } from 'react'
import './styles.css'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

export function CarouselItem({ children, key }: { children: ReactElement; key: number }) {
  const navigate = useNavigate()
  const openGallery = () => {
    navigate('/gallery')
  }

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  return (
    <div
      key={key}
      className={`carousel-item carousel-item${isTabletOrMobile ? '-mobile' : ''}`}
      onClick={openGallery}
    >
      {children}
    </div>
  )
}

export default function Carousel({ children }: { children: ReactElement[] }) {
  return (
    <div className='carousel'>
      <div className='inner'>
        {React.Children.map(children, (child) => {
          return React.cloneElement(child)
        })}
      </div>
    </div>
  )
}
