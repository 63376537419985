import { createSlice } from '@reduxjs/toolkit'

export const currentImageSlice = createSlice({
  name: 'currentImg',
  initialState: {
    imageUrl: '',
    imageCopyright: '',
  },
  reducers: {
    changeCurrent: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.imageUrl = action.payload.image
      state.imageCopyright = action.payload.imageCopyright
    },
  },
})

// Action creators are generated for each case reducer function
export const { changeCurrent } = currentImageSlice.actions

export default currentImageSlice.reducer
