import React from 'react'
import { dataPrivacyText } from './dataPrivacyText'
import { useSelector } from 'react-redux'
import { GlobalStateType } from '../../types/globalStateType'

export type DataPrivacyParams = {
  fullName: string
  profession: string
  address: string
  socialMedia: {
    instagram: string
    email: string
  }
}

export default function Impressum() {
  const { fullName, profession } = useSelector((state: GlobalStateType) => state.artistInformation)
  const { address, socialMedia } = useSelector((state: GlobalStateType) => state.artistContactInfo)
  return (
    <div className='external-wrapper'>
      <h1>Datenschutz</h1>
      <div
        dangerouslySetInnerHTML={{
          __html: dataPrivacyText({ fullName, profession, address, socialMedia }),
        }}
      />
    </div>
  )
}
