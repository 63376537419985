import React from 'react'
import './App.css'
import { Routes, Route } from 'react-router-dom'
import Home from './sections/home'
import NavBar from './components/navBar'
import Imprint from './sections/Imprint'
import DataPrivacy from './sections/DataPrivacy'
import Gallery from './components/gallery'
import { useDispatch } from 'react-redux'
import { setArtistPersonalInfromation } from './store/feature/artistInformation/artistInformationSlice'
import { setArtistContactInfo } from './store/feature/artistContactInfo/artistContactInfoSlice'
import {
  getArtistAudios,
  getArtistBiography,
  getArtistContactInfo,
  getArtistImages,
  getArtistInfo,
  getArtistSchedule,
  getArtistYoutubeVideos,
} from './connect/connect'
import { setAudios } from './store/feature/artistAssets/audiosSlice'
import { setYoutubeVideos } from './store/feature/artistAssets/youtubeVideoSlice'
import { setImages } from './store/feature/artistAssets/imagesSlice'
import { setSchedule } from './store/feature/artistSchedule/scheduleSlice'
import { setArtistBiography } from './store/feature/artistBiography/artistBiographySlice'

function App() {
  const dispatch = useDispatch()

  React.useEffect(() => {
    getArtistInfo()
      .then((data) => {
        if (data) dispatch(setArtistPersonalInfromation(data))
      })
      .catch((error) => console.error('error: ', error))

    getArtistContactInfo()
      .then((data) => {
        if (data) dispatch(setArtistContactInfo(data))
      })
      .catch((error) => console.error('error: ', error))

    getArtistAudios()
      .then((data) => {
        if (data) dispatch(setAudios(data))
      })
      .catch((error) => console.error('error: ', error))

    getArtistYoutubeVideos()
      .then((data) => {
        if (data) dispatch(setYoutubeVideos(data))
      })
      .catch((error) => console.error('error: ', error))

    getArtistImages()
      .then((data) => {
        if (data) dispatch(setImages(data))
      })
      .catch((error) => console.error('error: ', error))

    getArtistSchedule()
      .then((data) => {
        if (data) dispatch(setSchedule(data))
      })
      .catch((error) => console.error('error: ', error))

    getArtistBiography().then((data) => {
      if (data) {
        dispatch(setArtistBiography(data.data))
      }
    })
  }, [])

  return (
    <div>
      <NavBar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/imprint' element={<Imprint />} />
        <Route path='/dataPrivacy' element={<DataPrivacy />} />
        <Route path='/gallery' element={<Gallery />} />
      </Routes>
    </div>
  )
}

export default App
